export const reviewsData = [
  
  {
    id: 1,
    imageSrc: require('../Images/reviews/reviews1.png'),
  },
  {
    id: 2,
    imageSrc: require('../Images/reviews/reviews2.png'),
  },
  {
    id: 3,
    imageSrc: require('../Images/reviews/reviews3.png'),
  },
  {
    id: 4,
    imageSrc: require('../Images/reviews/reviews4.png'),
  },
  {
    id: 5,
    imageSrc: require('../Images/reviews/reviews5.png'),
  },
  {
    id: 6,
    imageSrc: require('../Images/reviews/reviews6.png'),
  },
  {
    id: 7,
    imageSrc: require('../Images/reviews/reviews7.png'),
  },
  {
    id: 8,
    imageSrc: require('../Images/reviews/reviews8.jpg'),
  },
  {
    id: 11,
    imageSrc: require('../Images/reviews/reviews11.jpg'),
  },
  {
    id: 12,
    imageSrc: require('../Images/reviews/reviews12.png'),
  },
  {
    id: 13,
    imageSrc: require('../Images/reviews/reviews13.png'),
  },
  {
    id: 14,
    imageSrc: require('../Images/reviews/reviews14.png'),
  },
  {
    id: 15,
    imageSrc: require('../Images/reviews/reviews15.jpg'),
  },
  {
    id: 16,
    imageSrc: require('../Images/reviews/reviews16.jpg'),
  },
  {
    id: 17,
    imageSrc: require('../Images/reviews/reviews17.jpg'),
  },
  {
    id: 18,
    imageSrc: require('../Images/reviews/reviews18.jpg'),
  },
  {
    id: 19,
    imageSrc: require('../Images/reviews/reviews19.jpg'),
  },
  {
    id: 20,
    imageSrc: require('../Images/reviews/reviews20.jpg'),
  },
  {
    id: 21,
    imageSrc: require('../Images/reviews/reviews21.jpg'),
  },
  {
    id: 22,
    imageSrc: require('../Images/reviews/reviews22.jpg'),
  },
  {
    id: 23,
    imageSrc: require('../Images/reviews/reviews23.jpg'),
  },
];
